import React from 'react'
import { Link } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import ProductCard from '../components/productCard/productCard'
import Carousel from '../components/carousel/carousel'

import abacusLogo from '../images/LogoRGB.png'

import { carousel } from '../conf/carousel.js'

import personalstammdaten from '../images/pages/index/lohnbuchhaltung-personalstamm-de.png'
import hrportal from '../images/pages/index/HRportalMobile.png'
import lohnbuchhaltung from '../images/pages/index/lohnbuchhaltung.png'
import abaclick from '../images/pages/index/abaclick.jpg'
import reporting from '../images/pages/index/reporting.png'
import prozesse from '../images/pages/index/prozesse.jpg'
import xpert from '../images/pages/index/XpertHRM.jpg'
import zep from '../images/pages/index/zep.jpg'
import Certicat from '../images/pages/index/Certicat.png'
import services from '../images/pages/index/Services.png'

const IndexPage = () => (
  <Layout>
    <section id="intro">
      <Carousel images={carousel}> 
        <div>
          <h2>Glückliche Mitarbeitende machen ein Unternehmen erfolgreich.</h2>
          <span>
            <p>
              Als Abacus Vertriebs- und Entwicklungspartner sind wir <b>die</b> Spezialisten für Lohn, HR-Prozesse und Zeitmanagement.
            </p>
            <p>
              Unser strategischer HR Fokus gilt seit vielen Jahren bei Personalverantwortlichen als Erfolgsfaktor. Die Kombination von IT-
              und HR-Fachwissen befähigt uns, die Anforderungen an ein modernes Personalmanagement zu verstehen und HR-Projekte erfolgreich umzusetzen.
            </p>
          </span>
          <Link to="kontakt">Kontakt</Link>
        </div>
      </Carousel>
      <section className="abacus">
        <img src={abacusLogo} alt="Abacus Logo" />
        <div className="text">Die HR-, Lohn-, Prozess- und Zeit-Spezialisten</div>
      </section>
    </section>

    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
      <section id="about" className="section-wrapper">
        <div className="section-header">
          <h3>LEHMANN+PARTNER Informatik</h3>
        </div>
        <p>
          Mit unserem Team von Wirtschaftsinformatikern und Softwareentwicklern haben wir uns seit 1995 auf den Fachbereich Personalmanagement spezialisiert.
          Wir unterstützen und beraten renommierte Unternehmen in den Bereichen <a href="https://www.abacus.ch/de/produkte/enterprise/lohnsoftware/"> Lohnbuchhaltung</a>,
          <a href="https://www.abacus.ch/de/produkte/cloud/myabacus/"> Mitarbeiterportal (ESS / MSS)</a>, HR- Prozesse und
          <a href="https://www.abacus.ch/de/produkte/enterprise/arbeitszeiterfassung/"> Zeitmanagement</a>.
          Unser Fokussieren auf Personalmanagement garantiert unseren Kunden, dass wir über vertieftes und modernes HR Fachwissen verfügen.
          Wir können mit Personalverantwortlichen fachkundig kommunizieren, kompetent beraten und beste Lösungen mit HR-Projekten implementieren.
        </p>
        <p>
          Als Vertriebs- und Entwicklungspartner der Abacus Research AG waren wir wesentlich an der Konzeption der Abacus Generation four (G4) beteiligt.
          Unsere Erfahrung und unser umfassendes HR Wissen floss in das neue Abacus Human Resources Management ein und heute deckt die Abacus Software alle HR Bedürfnisse ab.
        </p>
        <br />
        <p>
          Ebenfalls für die Abacus HR-Software wurde unsere Softwareentwicklung ZEP Zeugnisprozess adaptiert. Dieser HR Prozess vereinfacht, koordiniert und automatisiert das
          Erstellen von Arbeitsbestätigungen, Zwischenzeugnissen und Schlusszeugnissen. Die enorme Effizienzsteigerung basiert auf einer hinterlegten Textdatenbank, einer
          Schnittstelle zu Personaldaten, firmeneignen Beurteilungskriterien und dem rollenbasierenden Zuweisen von Aufgaben. <a href="https://www.zeugnisprozesse.ch">mehr</a>
        </p>
        <p>
          Unsere Kooperationsverträge mit anderen Abacusvertriebspartnern garantieren unseren Kunden, dass auch andere ERP Anforderungen kompetent abgedeckt sind.
        </p>
      </section>
    </ScrollAnimation>
    <div className="products-wrapper">
      <section id="products" className="section-wrapper">
        <div className="section-header">
          <h3>Produkte und Dienstleistungen</h3>
          <div className="row products-cols">
            <ProductCard
              title="Personalinformationssystem"
              desc="Abacus"
              icon={faSearch}
              img={personalstammdaten}
              navigateTo="/products/abacusHR"
              delay={0}
            />
            <ProductCard
              title="Lohnbuchhaltung/Payroll"
              desc="Abacus Payroll"
              icon={faSearch}
              img={lohnbuchhaltung}
              navigateTo="/products/payroll"
              delay={0}
            />
            <ProductCard
              title="HR Portal"
              desc="Zentrale Kommunikationsplattform"
              icon={faSearch}
              img={hrportal}
              navigateTo="/products/hrportal"
              delay={100}
            />
            <ProductCard
              title="HR Mobil"
              desc="AbaCliK"
              icon={faSearch}
              img={abaclick}
              navigateTo="/products/hrmobil"
              delay={200}
            />
            <ProductCard
              title="HR Prozesse"
              desc="Wir strukturieren und optimieren HR Prozesse"
              icon={faSearch}
              img={prozesse}
              navigateTo="/products/hrprozesse"
              delay={200}
            />
            <ProductCard
              title="HR Reporting"
              desc="Mehr als nur Auswertungen"
              icon={faSearch}
              img={reporting}
              navigateTo="/products/hrreporting"
              delay={300}
            />
            <ProductCard
              title="Xpert.HRM"
              desc="Migration, Support, Schnittstellen"
              icon={faSearch}
              img={xpert}
              navigateTo="/products/xpert"
              delay={300}
            />
            <ProductCard
              title="Dienstleistungen"
              desc="Dienstleistungen"
              icon={faSearch}
              img={services}
              navigateTo="/services"
              delay={400}
            />
            <ProductCard
              title="ZEP"
              desc="ZEP - Zeugnisprozess"
              icon={faSearch}
              img={zep}
              href="https://www.zeugnisprozesse.ch/"
              delay={400}
            />
            <ProductCard
              title="Certicat"
              desc="Certicat - Arbeitszeugnis online generieren"
              icon={faSearch}
              img={Certicat}
              href="https://www.certicat.ch/"
              delay={400}
            />
          </div>
        </div>
      </section>
    </div>
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
      <section id="contact" className="section-bg">
        <div className="container">
          <div className="section-header">
            <h3>Kontakt</h3>
            <p>Wir freuen uns über Ihre Kontaktaufnahme.</p>
          </div>
          <div className="row contact-info">
            <div className="col-md-4">
              <div className="contact-address">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" color="#9acf47" />
                <h3>Adresse</h3>
                <p><a href="https://www.google.ch/maps/place/Pilatusstrasse+39,+6003+Luzern/@47.0478693,8.3021381,17z/data=!3m1!4b1!4m6!3m5!1s0x478ffba1a5c57c4f:0xcdf5e9a40f7479ce!8m2!3d47.0478693!4d8.304713!16s%2Fg%2F11c5b6c1kt?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D">LEHMANN+PARTNER Informatik AG, Pilatusstrasse 39, CH-6003 Luzern</a></p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-phone">
                <FontAwesomeIcon icon={faPhone} size="3x" color="#9acf47" />
                <h3>Telefon</h3>
                <p><a href="tel:+41412273070">+41 41 227 3070</a></p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-email">
                <FontAwesomeIcon icon={faEnvelope} size="3x" color="#9acf47" />
                <h3>Email</h3>
                <p><a href="mailto:info@lepa.ch">info@lepa.ch</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  </Layout >
)

export default IndexPage
